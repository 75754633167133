.Hero{
    margin-top:119px;
}


.Hero-container{
    width:1250px;
    margin:auto;
    display:flex;
    justify-content:space-between;
}


.Hero-text{
    margin-top:100px;
    width:602px;
}

.Hero-main-text{
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
    display: flex;
    text-align: left;
    letter-spacing: -0.3px;
    
}

.Hero-subText{
    margin-top:36px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: -0.3px;
    text-align:left;

    color: #C4C4C4;
}

@media screen and (max-width: 1350px) {
    .Hero-container{
        width:90%;
    }
}

@media screen and (max-width: 1050px){
    .Hero-text{
        margin-top:0px;
        width:100%;
    }
    /* .Hero-main-text{
        font-size:44px;
    }
    .Hero-subText{
        font-size:18px;
    }  */
    .Hero-image{
        display:none;
    }
}

@media screen and (max-width: 550px){
    .Hero{
        margin-top:50px;
    }
    .Hero-main-text{
        font-size:40px;
    }
    .Hero-subText{
        font-size:16px;
        width:95%
    }
    .Hero-image{
        display:block;
        margin-top:100px;
    }
    .Hero-container{
        flex-direction:column;
    }
    .Hero-image img{
        width:90vw;
    }
}