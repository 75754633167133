.Products{
    margin-top:120px;
}

a{
    color:#56BD82;
}


.Products-container{
    width:1250px;
    margin:auto;
    text-align:left;
}

.Products-header{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;

    color: #FAFAFA;
}

.Products-Trakka{
    margin-top:96px;
}
.Products-Trakka-info{
    margin-top:24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.3px;
    color: #C4C4C4;
}

.Products-Trakka-visit{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    color: #56BD82;
    width:127px;
    position:relative;
    margin-top:36px;
}

.Products-Trakka-visit img{
    align-items:center;
    justify-content: center;
    position:absolute;
}

@media screen and (max-width: 1350px) {
    .Products-container{
        width:90%;
    }
}

/* @media screen and (max-width: 1050px){
    .Products-header{
        font-size:32px;
    }
    .Products-Trakka-info{
        font-size:16px;
    }
    .Products-Trakka-visit{
        font-size:14px;
    }
    .Products-Trakka-visit img{
        width:20px;
        margin-top:1px;
    }
} */

@media screen and (max-width: 550px){
    .Products{
        margin-top:100px;
    }
    .Products-header{
        font-size:30px;
    }
    .Products-Trakka img{
        width:115px;
    }
    .Products-Trakka-info{
        font-size:16px;
        font-weight:400;
        line-height: 33px;
        width:95%;
        letter-spacing: -0.3px;
    }
    .Products-Trakka{
        margin-top:50px;
    }


}