@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');


*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
body{
  background-color:#121212;
}

.App {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color:#fff;
  
}



/* width */
::-webkit-scrollbar {
  width: 7px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: #121212;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#C4C4C4;
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}