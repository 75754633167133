.Leadership{
    margin-top:120px;
}

.Leadership-container{
    width:1250px;
    margin:auto;
    text-align:left;
}

.Leadership-header{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;

    color: #FAFAFA;
}

.Leadership-leaders{
    margin-top:96px;
    display:flex;
    justify-content:space-between;

}

.Leader-image img{
    width:234px;
    height:300px;
    /* border: 2px solid #757575; */
    border-radius: 20px;
}

.Leader{
    display:flex;
        flex-direction:column;
        margin-right:25%;

}

.Leader-text{
    width:276px;
    margin-left:0px;
    margin-top:36px;
    text-align: left;
}

.Leader-quote{
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #C4C4C4;
}

.Leader-info{
    margin-top:48px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */


    color: #FFFFFF;
}



@media screen and (max-width: 1350px) {
    .Leadership-container{
        width:90%;
    }
    .Leader-text{
        /* width:200px; */
    }
}

@media screen and (max-width: 1050px){
    .Leadership-header{
        font-size:32px;
    }
    .Leader-text{
        width:170px;
        margin-top:10px;
    }

    .Leader-quote{
        font-size:14px;
        margin-top:10px;
    }
    .Leader-info{
        margin-top:28px;
        font-size:14px;
    }

}

@media screen and (max-width: 1000px){

    .Leader-quote{
        display:none;
    }
    .Leader-text{
        width:auto;
    }
    .Leadership-leaders{
        justify-content:flex-start;
    }
}

@media screen and (max-width: 550px){

    .Leadership{
        margin-top:100px;
    }

    .Leadership-leaders{
        margin-top:50px;
    }

    .Leadership-leaders{
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .Leader-image img{
        width:100%;
    }
    .Leader{
        margin-right:0px;
        margin-bottom:40px;
    }
    .Leadership-header{
        font-size:30px;
    }
    .Leader-text{
       margin:20px auto;
    }
    .Leader-info{
        font-size:16px;
        margin-top:0px;
        
    }
}