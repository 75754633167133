.Footer{
    margin-top:120px;
}

.Footer-container{
    width:1250px;
    margin:auto;
    text-align:center;
}

.Footer-container p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;

    color: #FFFFFF;
    padding-bottom:60px;
}

@media screen and (max-width: 1350px) {
    .Footer-container{
        width:90%;
    }
}

/* @media screen and (max-width: 1050px) {
    .Footer-container p{
        font-size:14px;
        padding-bottom:50px;
    }
} */


@media screen and (max-width: 550px){
    .Footer{
        margin-top:60px;
    }
    .Footer-container p{
        padding-bottom:36px;
        font-size:16px;
    }
}