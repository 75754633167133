.Navbar{
    
}

.Navbar-container{
    width:1250px;
    display:flex;
    align-items:flex-start;
    margin:auto;
}

.finstaq-logo img{
    width:152px;
    padding:36px 0;
    
}


@media screen and (max-width: 1350px) {
    .Navbar-container{
        width:90%;
    }
}

@media screen and (max-width: 550px){
    .finstaq-logo img{
        width:115px;
    }
}